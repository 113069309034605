import React, { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Grid } from "@mui/material";
import WalletDetails from "../../Components/Wallet/WalletDetails";
import Transfers from "../../Components/Wallet/Transfers";
import { getWalletData, setupTransfer, cashOut } from "../../Services/APIHandlers/WalletApi";
import AuthContext from "../../Navigation/AuthWrapper";
import { addUser } from "../../Services/Redux/Slices/User.Slice";
import { globalStyles, spacingStyles } from "../../assets/styles/global";
import { useTheme } from "@mui/material/styles";
import { Button } from "../../Components/Common/Buttons/Button"; // Your custom Button
import { LoadingButton } from "@mui/lab"; // Importing LoadingButton
import CircularProgress from "@mui/material/CircularProgress"; 
const Wallet = () => {
  const [showTransfers, setShowTransfer] = useState(false);
  const [isUpdatingBanking, setIsUpdatingBanking] = useState(false); // Loading state
  const details = useSelector((state) => state.wallet);
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const theme = useTheme();

  const getUserData = async () => {
    try {
      const storedUserData = await localStorage.getItem("user");
      setUserData(JSON.parse(storedUserData));
    } catch (error) {
      console.error("Error retrieving user data:", error);
    }
  };

  useEffect(() => {
    getUserData();
    const fetchData = async () => {
      setIsUpdatingBanking(true); // Start loading when fetching data
      try {
        const response = await getWalletData(auth.token);
        if (response.success) {
          console.log("Wallet Data:", response.data);
        } else {
          console.error("Error fetching wallet data:", response.message);
        }
      } catch (error) {
        console.error("Error fetching wallet data:", error);
      } finally {
        setIsUpdatingBanking(false); // Stop loading when done
      }
    };

    // fetchData();
  }, [auth.token]);

  const handleSetupTransferClick = async () => {
    setIsUpdatingBanking(true); // Set loading state
    try {
      const response = await setupTransfer(auth.token);
      if (response.data.user) {
        dispatch(addUser(response.data.user));
      }
      if (response.data.connect_url) {
        window.location.href = response.data.connect_url;
      }
    } catch (err) {
      console.error(err);
    } finally {
      // No need to set loading state here; handled in data fetching
    }
  };

  const handleCashOut = async () => {
    try {
      const response = await cashOut(auth.token);
      console.log(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box sx={{ ...spacingStyles.desktopMaxWidth, padding: "0 5px" }}>
      <Typography sx={{ maxWidth: "100vw", padding: "20px 0 40px" }} variant="h4" color={theme.palette.text.primary}>
        Wallet
      </Typography>

      <Grid container spacing={3}>
        {/* Left Column */}
        <Grid item xs={12} md={6} >
          <Box sx={{ ...globalStyles.flexCol, ...globalStyles.flexCenter, ...globalStyles.alignCenter }} mb={2}>
            <Typography variant="h5" color="text.secondary" gutterBottom>
              Current Balance
            </Typography>

            <Box display="flex" alignItems="center">
              <Typography variant="h3" color="success.main">
                $
              </Typography>
              <Typography variant="h2" color="success.main" ml={1}>
                {details?.current_stats?.cash_available || 0}
              </Typography>
            </Box>
            <Typography variant="subtitle1" color="text.secondary">
              USD
            </Typography>
          </Box>

          {/* Buttons */}
          <Box>
            {/* Transfer To Bank Button */}
            <Button
              disabled={
                userData?.stripe_payoutId === null 
                || details?.current_stats?.cash_available === 0
              }
              fill={userData?.stripe_payoutId ? "confirm" : "outline"}
              text="Transfer To Bank"
              onClick={handleCashOut}
              sx={{ marginBottom: '20px' }}
            />

            {/* Setup Transfer Button */}
            {userData?.stripe_payoutId === null && (
              <Button
                fill="solid"
                text="Setup Transfer"
                onClick={handleSetupTransferClick}
                sx={{ mb: 2, }}
              />
            )}

            {/* Update Banking Button with Loading Button */}
            {userData?.stripe_payoutId !== null && (
              <LoadingButton
                loading={isUpdatingBanking} // Show loading state
                variant="outlined"
                onClick={handleSetupTransferClick}
                size="large"
                sx={{ marginBottom: '20px',
                  width: "100%",
                  padding: "15px 20px",
                  fontWeight: "bold",
                  fontSize: 18,
                  
                 }} // Optional styling
             loadingIndicator={<CircularProgress color="info" size={36} />}
              >
                Update Banking
              </LoadingButton>
            )}
          </Box>
        </Grid>

        {/* Right Column */}
        <Grid item style={{ paddingTop: 'unset' }} xs={12} md={6} >
          <Box display="flex" justifyContent="center" mb={2} gap={1}>
            <Button
              fill={!showTransfers ? "select" : "outline"}
              text="Balance"
              onClick={() => setShowTransfer(false)}
            />
            <Button
              fill={showTransfers ? "select" : "outline"}
              text="Transfers"
              onClick={() => setShowTransfer(true)}
            />
          </Box>

          {/* Details */}
          {showTransfers ? <Transfers /> : <WalletDetails details={details} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Wallet;
