import { useDispatch, useSelector } from "react-redux";
import { setAmenities } from "../../Services/Redux/Slices/Onboarding.Slice";
import { globalStyles, spacingStyles } from "../../assets/styles/global";
import OnboardingButton from "../Common/Buttons/OnboardingButton";
import { Typography, useTheme } from "@mui/material"; // Importing Material-UI theme

export default function AmenitiesComponent() {
  const dispatch = useDispatch();
  const amenities = useSelector((state) => state.onboarding.amenities);
  const theme = useTheme(); // Access the current theme

  return (
    <div style={{ ...globalStyles.alignCenter, ...globalStyles.w100 }}>
      <Typography variant="h4" color={theme.palette.text.primary}>
        Do you have any of these additional amenities?
      </Typography>
      <Typography variant="body1" style={spacingStyles.mb20} color={theme.palette.text.secondary}>
        Select all that apply
      </Typography>
      <div className="onborading-amenities-list">
        <OnboardingButton
          text="Swimming"
          selected={amenities.pool}
          showCusIcon={true}
          cusIconName="pool"
          onClick={() =>
            dispatch(setAmenities({ ...amenities, pool: !amenities.pool }))
          }
        />
        <OnboardingButton
          text="Indoor Track"
          selected={amenities.indoorTrack}
          showCusIcon={true}
          cusIconName="indoorTrack"
          onClick={() =>
            dispatch(
              setAmenities({
                ...amenities,
                indoorTrack: !amenities.indoorTrack,
              })
            )
          }
          iconHeight={18}
        />
        <OnboardingButton
          text="Outdoor Track"
          selected={amenities.outdoorTrack}
          showCusIcon={true}
          cusIconName="outdoorTrack"
          onClick={() =>
            dispatch(
              setAmenities({
                ...amenities,
                outdoorTrack: !amenities.outdoorTrack,
              })
            )
          }
        />
        <OnboardingButton
          text="Indoor Field"
          selected={amenities.indoorField}
          showCusIcon={true}
          cusIconName="indoorField"
          onClick={() =>
            dispatch(
              setAmenities({
                ...amenities,
                indoorField: !amenities.indoorField,
              })
            )
          }
        />
        <OnboardingButton
          text="Outdoor Field"
          selected={amenities.outdoorField}
          showCusIcon={true}
          cusIconName="outdoorField"
          onClick={() =>
            dispatch(
              setAmenities({
                ...amenities,
                outdoorField: !amenities.outdoorField,
              })
            )
          }
        />
        <OnboardingButton
          text="Basketball"
          selected={amenities.basketballCourt}
          showCusIcon={true}
          cusIconName="basketballCourt"
          onClick={() =>
            dispatch(
              setAmenities({
                ...amenities,
                basketballCourt: !amenities.basketballCourt,
              })
            )
          }
        />
        <OnboardingButton
          text="Tennis"
          selected={amenities.tennisCourt}
          showCusIcon={true}
          cusIconName="tennisCourt"
          onClick={() =>
            dispatch(
              setAmenities({
                ...amenities,
                tennisCourt: !amenities.tennisCourt,
              })
            )
          }
        />
        <OnboardingButton
          text="Racket Ball"
          selected={amenities.racketballCourt}
          showCusIcon={true}
          cusIconName="racketballCourt"
          onClick={() =>
            dispatch(
              setAmenities({
                ...amenities,
                racketballCourt: !amenities.racketballCourt,
              })
            )
          }
        />
        <OnboardingButton
          text="Volleyball"
          selected={amenities.volleyballCourt}
          showCusIcon={true}
          cusIconName="volleyballCourt"
          onClick={() =>
            dispatch(
              setAmenities({
                ...amenities,
                volleyballCourt: !amenities.volleyballCourt,
              })
            )
          }
        />
        <OnboardingButton
          text="Dance Floor"
          selected={amenities.danceFloor}
          showCusIcon={true}
          cusIconName="wood"
          onClick={() =>
            dispatch(
              setAmenities({ ...amenities, danceFloor: !amenities.danceFloor })
            )
          }
        />
        <OnboardingButton
          text="Aerial Yoga"
          selected={amenities.aerialYoga}
          showCusIcon={true}
          cusIconName="aerialYoga"
          onClick={() =>
            dispatch(
              setAmenities({ ...amenities, aerialYoga: !amenities.aerialYoga })
            )
          }
          iconHeight={35}
        />
        <OnboardingButton
          text="Dance Poles"
          selected={amenities.dancePoles}
          showCusIcon={true}
          cusIconName="dancePoles"
          onClick={() =>
            dispatch(
              setAmenities({ ...amenities, dancePoles: !amenities.dancePoles })
            )
          }
          iconHeight={32}
        />
        <OnboardingButton
          text="Trampoline"
          selected={amenities.trampoline}
          showCusIcon={true}
          cusIconName="trampoline"
          onClick={() =>
            dispatch(
              setAmenities({ ...amenities, trampoline: !amenities.trampoline })
            )
          }
          iconHeight={20}
        />
      </div>
    </div>
  );
}
