import * as React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Box, Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CustomProgressBar from "../Common/CustomProgressBar";
import { Button } from "../Common/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import getErrorMessage from "../../Services/Helpers/getErrorMessage";
import { spacingStyles } from "../../assets/styles/global";

const MyComponent = ({
  component,
  nextAction,
  previousAction,
  screenNum,
  screenTotal = 7,
  saveResponses,
  disabled,
  hideProgressBar = false,
  title = "",
  markAsComplete // Function to mark the current step as complete
}) => {
  const [submitting, setSubmitting] = React.useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const theme = useTheme(); // Get theme from ThemeProvider (light/dark mode)
  const [submitDisabled, setSubmitDisabled] = React.useState(false)

  const percentage = (screenNum / screenTotal).toFixed(2);

  const handleSubmit = async () => {
    try {
      setSubmitting(true);

      if (saveResponses) {
        const { data } = await saveResponses();
      }

      // Call markAsComplete to mark the current submenu item as complete
      if (markAsComplete) {
        markAsComplete();
      }

      // Move to the next step
      nextAction();
    } catch (error) {
      alert(getErrorMessage(error));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="desktopMaxWidth">
      <Box className="onboarding-status">
        {!hideProgressBar && (
          <>
            <Typography
              variant="h5"
              sx={{
                mt: 2,
                color: theme.palette.text.secondary, // Use theme text color
              }}
            >
              {title}
              {screenTotal > 1 && <> - {(Number(percentage) * 100).toFixed(0)}%</>}
            </Typography>
            <Box
              sx={{
                width: "50%",
                mt: 1,
              }}
            >
              <CustomProgressBar fraction={Number(percentage)} />
            </Box>
          </>
        )}
      </Box>

      <Box className="onboarding-component">
        {React.cloneElement(component, { setSubmitDisabled })} 
      </Box>

      <Box className="onboarding-cta">
        <Box
          onClick={previousAction}
          sx={{
            borderColor: "#707070", // Custom border color
            borderWidth: 2,
            borderStyle: "solid",
            borderRadius: 50,
            padding: 2, // Material-UI's spacing unit (equals 8px * 2 = 16px)
            textAlign: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <FaArrowLeft color={theme.palette.grey[500]} size={30} />
        </Box>

        <Box sx={{ pl: 2 }}>
          <Button
            onClick={handleSubmit}
            text={"Continue"}
            disabled={submitting || disabled || submitDisabled} // Update disabled state
          />
        </Box>
      </Box>
    </div>
  );
};

export default MyComponent;
