import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Switch,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  ButtonGroup,
  Typography,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { addSchedule, selectScheduleState } from "../../Services/Redux/Slices/Schedule.Slice";
import { TbCirclePlus, TbCopy } from "react-icons/tb";
import { RiCloseCircleFill } from "react-icons/ri";
import moment from "moment";

const initialSchedule = {
  Sun: { open: false, hours: [] },
  Mon: { open: false, hours: [] },
  Tue: { open: false, hours: [] },
  Wed: { open: false, hours: [] },
  Thu: { open: false, hours: [] },
  Fri: { open: false, hours: [] },
  Sat: { open: false, hours: [] },
};

const dayNameMapping = {
  Sun: "Sunday",
  Mon: "Monday",
  Tue: "Tuesday",
  Wed: "Wednesday",
  Thu: "Thursday",
  Fri: "Friday",
  Sat: "Saturday",
};

const timeSlots = [
  "04:00:00", "04:30:00", "05:00:00", "05:30:00", "06:00:00",
  "06:30:00", "07:00:00", "07:30:00", "08:00:00", "08:30:00",
  "09:00:00", "09:30:00", "10:00:00", "10:30:00", "11:00:00",
  "11:30:00", "12:00:00", "12:30:00", "13:00:00", "13:30:00",
  "14:00:00", "14:30:00", "15:00:00", "15:30:00", "16:00:00",
  "16:30:00", "17:00:00", "17:30:00", "18:00:00", "18:30:00",
  "19:00:00", "19:30:00", "20:00:00", "20:30:00", "21:00:00",
  "21:30:00", "22:00:00", "22:30:00", "23:00:00", "23:30:00",
];

const OperationTime = () => {
  const scheduleState = useSelector(selectScheduleState);
  const [scheduleData, setScheduleData] = useState(initialSchedule);
  const [selectedDays, setSelectedDays] = useState([]);
  const bottomRef = useRef(null); // Step 1: Create a reference for the bottom element
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (scheduleState && isInitialLoad) {
      const transformedData = transformStateToData(scheduleState);
      setScheduleData(transformedData);
      setIsInitialLoad(false);
    }
  }, [scheduleState]);

  useEffect(() => {
    if (selectedDays.length > 0 && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedDays]);

  const transformStateToData = (scheduleState) => {
    const transformedData = Object.keys(scheduleState).reduce((acc, day) => {
      const daySlots = scheduleState[day].filter(slot => slot.select);
      const hours = [];
      if (daySlots.length > 0) {
        let start = daySlots[0].time;
        let end = daySlots[0].time;
        for (let i = 1; i < daySlots.length; i++) {
          const prevTime = moment(daySlots[i - 1].time, "HH:mm:ss");
          const currentTime = moment(daySlots[i].time, "HH:mm:ss");
          if (currentTime.diff(prevTime, "minutes") === 30) {
            end = daySlots[i].time;
          } else {
            hours.push({ start, end });
            start = daySlots[i].time;
            end = daySlots[i].time;
          }
        }
        hours.push({ start, end });
      }
      acc[day] = { open: daySlots.length > 0, hours };
      return acc;
    }, {});

    return transformedData;
  };

  const toggleDaySelection = (day) => {
    setSelectedDays((prevDays) =>
      prevDays.includes(day) ? prevDays.filter((d) => d !== day) : [...prevDays, day]
    );
  };

  const handleOpenCloseChange = (day) => {
    const isOpen = !scheduleData[day]?.open;
    const updatedHours = isOpen && scheduleData[day]?.hours.length === 0
      ? [{ start: "", end: "" }]
      : scheduleData[day]?.hours || [];
    const updatedSchedule = {
      ...scheduleData,
      [day]: { open: isOpen, hours: isOpen ? updatedHours : [] },
    };
    setScheduleData(updatedSchedule);
    dispatchTransformedSchedule(updatedSchedule);
  };

  const handleTimeChange = (day, index, key, value) => {
    const updatedHours = scheduleData[day]?.hours.map((hour, i) => {
      if (i === index) {
        return { ...hour, [key]: value };
      }
      return hour;
    });
    const updatedSchedule = {
      ...scheduleData,
      [day]: { ...scheduleData[day], hours: updatedHours },
    };
    setScheduleData(updatedSchedule);
    dispatchTransformedSchedule(updatedSchedule);
  };

  const addTimeSlot = (day) => {
    const updatedSchedule = {
      ...scheduleData,
      [day]: {
        ...scheduleData[day],
        hours: [...(scheduleData[day]?.hours || []), { start: "", end: "" }],
      },
    };
    setScheduleData(updatedSchedule);
  };

  const handleDeleteTimeSlot = (day, index) => {
    const updatedHours = scheduleData[day].hours.filter((_, i) => i !== index);
    const updatedSchedule = {
      ...scheduleData,
      [day]: { ...scheduleData[day], hours: updatedHours },
    };
    setScheduleData(updatedSchedule);
    dispatchTransformedSchedule(updatedSchedule);
  };

  const isDayGreen = (day) => {
    return scheduleData[day]?.open && scheduleData[day].hours.some(hour => hour.start && hour.end);
  };

  const dispatchTransformedSchedule = (scheduleData) => {
    const transformedSchedule = Object.keys(scheduleData).reduce((acc, day) => {
      acc[day] = timeSlots.map((time) => {
        const selected = scheduleData[day].open &&
          scheduleData[day].hours.some(({ start, end }) => {
            const startTime = new Date(`1970-01-01T${convertTo24HourFormat(start)}Z`);
            const endTime = new Date(`1970-01-01T${convertTo24HourFormat(end)}Z`);
            const slotTime = new Date(`1970-01-01T${time}Z`);
            return slotTime >= startTime && slotTime < endTime;
          });
        return { time, select: selected };
      });

      return acc;
    }, {});
    dispatch(addSchedule(transformedSchedule));
  };

  const convertTo24HourFormat = (time) => {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":");
    if (modifier === "PM" && hours !== "12") {
      hours = String(parseInt(hours, 10) + 12);
    }
    if (modifier === "AM" && hours === "12") {
      hours = "00";
    }
    return `${hours}:${minutes}:00`;
  };

  const copyToAll = (day) => {
    const sourceHours = scheduleData[day]?.hours || [];
    const updatedSchedule = { ...scheduleData };
    selectedDays.forEach((selectedDay) => {
      if (selectedDay !== day) {
        updatedSchedule[selectedDay] = {
          ...updatedSchedule[selectedDay],
          hours: [...sourceHours],
        };
      }
    });
    setScheduleData(updatedSchedule);
    dispatchTransformedSchedule(updatedSchedule);
  };

  const renderOpenDays = () => {
    const sortedDays = Object.keys(scheduleData).sort((a, b) => {
      const dayOrder = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return dayOrder.indexOf(a) - dayOrder.indexOf(b);
    });
    const filteredDays = sortedDays.filter(
      (day) =>
        scheduleData[day].open &&
        scheduleData[day].hours.some((hour) => hour.start && hour.end)
    );
    if (filteredDays.length === 0) {
      return (
        <Typography variant="h6" sx={{ color: theme.palette.text.secondary }}>
          No Slots Available
        </Typography>
      );
    }
    return filteredDays.map((day) => (
      <Box key={day} sx={{ marginBottom: 2 }}>
        <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
          {dayNameMapping[day]}
        </Typography>
        <Box sx={{ paddingLeft: 3 }}>
          {scheduleData[day].hours
            .filter((hour) => hour.start && hour.end)
            .map((hour, index) => (
              <Typography key={index} sx={{ color: theme.palette.text.secondary }}>
                {moment(hour.start, "HH:mm:ss").format("h:mm a")} -{" "}
                {moment(hour.end, "HH:mm:ss").format("h:mm a")}
              </Typography>
            ))}
        </Box>
      </Box>
    ));
  };

  return (
    <Container sx={{ padding: "unset", textAlign: "left", color: theme.palette.text.primary, paddingBottom: 10, paddingRight: 1, paddingLeft: 1 }}>
      <Box  sx={{ flexDirection: "column", marginBottom: 3 }}>
        <Box sx={{ boxShadow: theme.shadows[1], marginRight: "auto", borderRadius: "var(--radius)", padding: "15px", backgroundColor: theme.palette.background.paper }}>
          <Typography variant="h4" sx={{ textAlign: "center", marginBottom: 2, color: theme.palette.text.primary }}>
            Availability Schedule
          </Typography>
          {renderOpenDays()}
        </Box>
      </Box>

      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", overflowX: 'auto', alignItems: "flex-start", marginTop: 5 }}>
        <>
          <Typography variant="body1" color={theme.palette.text.secondary} sx={{ width: "100%", marginBottom: 1 }}>
            Select the days:
          </Typography>
          <Box sx={{ marginLeft: 2, overflowX: "hidden" }}>
            <ButtonGroup variant="contained" sx={{ boxShadow: 'none', display: 'block' }}>
              {Object.keys(scheduleData).map((day) => (
                <Button
                  key={day}
                  onClick={() => toggleDaySelection(day)} 
                  sx={{
                    backgroundColor: selectedDays.includes(day) ? theme.palette.primary.main : isDayGreen(day) ? theme.palette.success.light : theme.palette.grey[600],
                    margin: "0 2px",
                    color: theme.palette.common.white,
                    fontWeight: "600",
                    width: '9%',
                  }}
                >
                  {day}
                </Button>
              ))}
            </ButtonGroup>

            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginBottom: 1, marginTop: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: 2, marginBottom: 1 }}>
                <Box sx={{ width: 16, height: 16, borderRadius: "50%", backgroundColor: theme.palette.primary.main, marginRight: 1 }} />
                <Typography variant="body2" color={theme.palette.text.primary}>Selected Day</Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: 2, marginBottom: 1 }}>
                <Box sx={{ width: 16, height: 16, borderRadius: "50%", backgroundColor: theme.palette.success.light, marginRight: 1 }} />
                <Typography variant="body2" color={theme.palette.text.primary}>Available Time Slots</Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: 2, marginBottom: 1 }}>
                <Box sx={{ width: 16, height: 16, borderRadius: "50%", backgroundColor: theme.palette.grey[600], marginRight: 1 }} />
                <Typography variant="body2" color={theme.palette.text.primary}>No Time Slots</Typography>
              </Box>
            </Box>
          </Box>
        </>

        <Box sx={{ width: "100%", marginTop: 5 }}>
          {selectedDays.length > 0 && (
            <>
              <Typography variant="body1" color={theme.palette.text.secondary}>
                Add time slots:
              </Typography>

              <Box sx={{ marginLeft: 2 }}>
                {selectedDays.map((day) => (
                  <Box key={day} sx={{ borderBottom: "1px solid gray", marginTop: 1 }}>
                    <Typography variant="h6" sx={{ marginRight: 2, textAlign: "left" }}>
                      {dayNameMapping[day]}
                    </Typography>

                    <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", marginBottom: 2, marginLeft: 2 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={scheduleData[day]?.open || false}
                            onChange={() => handleOpenCloseChange(day)}
                            color="primary"
                          />
                        }
                        label={scheduleData[day]?.open ? "Slots Available" : "Add Time Slots"}
                      />
                    </Box>

                    {scheduleData[day]?.open && (
                      <>
                        {scheduleData[day]?.hours.map((hour, index) => (
                          <Box key={index} sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
                            <Select
                              value={hour.start}
                              onChange={(e) => handleTimeChange(day, index, "start", e.target.value)}
                              sx={{ marginRight: 2, minWidth: 120 }}
                            >
                              {timeSlots.map((time) => (
                                <MenuItem key={time} value={time}>
                                  {moment(time, "HH:mm:ss").format("h:mm a")}
                                </MenuItem>
                              ))}
                            </Select>

                            <Typography sx={{ marginX: 2, color: theme.palette.text.primary }}>to</Typography>

                            <Select
                              value={hour.end}
                              onChange={(e) => handleTimeChange(day, index, "end", e.target.value)}
                              sx={{ minWidth: 120 }}
                            >
                              {timeSlots
                                .filter((time) => moment(time, "HH:mm:ss").isAfter(moment(hour.start, "HH:mm:ss")))
                                .map((time) => (
                                  <MenuItem key={time} value={time}>
                                    {moment(time, "HH:mm:ss").format("h:mm a")}
                                  </MenuItem>
                                ))}
                            </Select>

                            <RiCloseCircleFill
                              onClick={() => handleDeleteTimeSlot(day, index)}
                              style={{ marginLeft: "10px", cursor: "pointer", color: theme.palette.error.main }}
                            />
                          </Box>
                        ))}

                        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
                          <Button
                            onClick={() => addTimeSlot(day)}
                            sx={{
                              color: theme.palette.text.secondary,
                              backgroundColor: "transparent",
                              marginTop: 2,
                              marginBottom: 2,
                              marginLeft: 2,
                              display: "flex",
                              alignItems: "center",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                              },
                            }}
                            startIcon={<TbCirclePlus />}
                          >
                            Add Time Slot
                          </Button>
                          {selectedDays.filter((selectedDay) => scheduleData[selectedDay]?.open && scheduleData[selectedDay]?.hours.length > 0).length > 1 && (
                            <Button
                              onClick={() => copyToAll(day)}
                              sx={{
                                marginLeft: 2,
                                backgroundColor: "transparent",
                                color: theme.palette.text.secondary,
                                textTransform: "none",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                                },
                              }}
                              startIcon={<TbCopy />}
                            >
                              Copy to All
                            </Button>
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                ))}
              </Box>
            </>
          )}
        </Box>

        <div ref={bottomRef} /> {/* Step 2: Add this element to scroll into view */}
      </Box>
    </Container>
  );
};

export default OperationTime;
