import React, { useState, useEffect, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TbMapSearch } from "react-icons/tb";
import { IoCalendarSharp } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { IoWallet } from "react-icons/io5";
import {
  colors,
  spacingStyles,
  globalStyles,
} from "../../assets/styles/global";
import "../../assets/styles/global.css";
import { Button } from "../Common/Buttons/Button";
import { getWalletData } from "../../Services/APIHandlers/WalletApi";
import AuthContext from "../../Navigation/AuthWrapper";
import { addWalletDetails } from "../../Services/Redux/Slices/Wallet.Slice";

const DashWelcome = ({ openImageUploader }) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const auth = useContext(AuthContext);
  const wallet = useSelector((state) => state.wallet);

  const businessData = JSON.parse(localStorage.getItem("business"));
  const userData = JSON.parse(localStorage.getItem("user"));

  //console.log(userData);

  useEffect(() => {
    const getWalletDetails = async () => {
      try {
        const response = await getWalletData(auth.token);
        if (response.status === 200) {
          dispatch(addWalletDetails(response.data));
        }
      } catch (err) {
        console.error(err);
      }
    };

   // getWalletDetails();
    //console.log(businessData);
  }, [auth.token, dispatch]);

  var horBackgroundStyle = {
    // backgroundImage: "url(" + businessData?.photo + ")",
    backgroundImage: "url(" + (userData?.profilepic ? userData.profilepic : require("../../assets/camera.png")) + ")",
    height: "108px",
    width: "108px",
    borderRadius: "5em",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div className="dash-box" style={{ ...spacingStyles.p10 }}>
      <div className="flexCol flexCenter">
        <h2>
          <span className="orange">Welcome, </span>
          {userData?.user_name + " " + userData?.user_lastname}
        </h2>
        <div className="flexRow">
          <div
            className="shadow"
            style={horBackgroundStyle}
            onClick={openImageUploader}
          ></div>
          <div
            className="flexCol left centerVert"
            style={{ ...spacingStyles.pr10, ...spacingStyles.ml20 }}
          >
            <p style={{ ...spacingStyles.munset, ...spacingStyles.mt5 }}>
              Account Status:{" "}
              <span>
                {userData.stripe_user_id !== null && (
                  <span className="green" style={{ fontSize: "1.1rem" }}>
                    Live
                  </span>
                )}
                {userData.stripe_user_id === null && (
                  <span className="danger" style={{ fontSize: "1.1rem" }}>
                    In-Active
                  </span>
                )}
              </span>
            </p>
            <p style={{ ...spacingStyles.munset, ...spacingStyles.mt5 }}>
              Current Balance:{" "}
              <span className="green">
                ${wallet?.current_stats?.cash_available}
              </span>
            </p>
          </div>
        </div>
        <div
          className="dashMenu flexRow flexCenter w100"
          style={{
            ...spacingStyles.plr20,
            ...spacingStyles.pt20,
            ...spacingStyles.mt10,
          }}
        >
          {/* <div className="dash-icon-box">
            <div className="flexCol flexCenter" onClick={() => navigation("/Search")} title="Search">
              <TbMapSearch color={globalStyles.gray.color} size={40} />
              <label style={{ color: globalStyles.gray.color }}>Search</label>
            </div>
          </div>
          <div className="dash-icon-box">
            <div className="flexCol flexCenter"
              onClick={() => navigation("/Availability")}
              title="Availability"
            >
              <IoCalendarSharp color={globalStyles.gray.color} size={40} />
              <label style={{ color: globalStyles.gray.color }}>Schedule</label>
            </div>
          </div>
          <div className="dash-icon-box">
            <div className="flexCol flexCenter" onClick={() => navigation("/Wallet")} title="Wallet">
              <IoWallet color={globalStyles.gray.color} size={40} />
              <label style={{ color: globalStyles.gray.color }}>Wallet</label>
            </div>
          </div>
          <div className="dash-icon-box">
            <div className="flexCol flexCenter" onClick={() => navigation("/Profile")} title="Profile">
              <IoMdSettings color={globalStyles.gray.color} size={40} />
              <label style={{ color: globalStyles.gray.color }}>Settings</label>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default DashWelcome;
