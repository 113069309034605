import { useState, useContext } from 'react';
import { nav } from "../Services/Constants/navigation";
import { useTheme } from '@mui/material/styles';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import HomeIcon from '@mui/icons-material/Home';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AuthContext from "./AuthWrapper";
import OnboardingSideMenu from './OnboardingSideMenu'; // Import the OnboardingSideMenu component
import DarkModeSwitch from '../Components/Common/DarkModeSwitch';
import { spacingStyles } from '../assets/styles/global';

import { useNavigate } from "react-router-dom";

// Initialize completion status for onboarding items
const initialCompletionState = {
    Setup: false,
    Description: false,
    Gallery: false,
    Space: false,
    Amenities: false,
    Location: false,
    Schedule: false,
    Special: false,
    SetupComplete: false,
};

const DeskSideMenu = ({ activeScreen, onMenuClick }) => {
    const [isMyListingOpen, setMyListingOpen] = useState(false); // State for My Listing collapse
    const [completionStatus, setCompletionStatus] = useState(initialCompletionState); // Completion status for onboarding
    const auth = useContext(AuthContext);
    const isAuthenticated = auth.token !== "";
    const { darkMode, toggleDarkMode, role_id } = useContext(AuthContext);
    const theme = useTheme();
    const navigation = useNavigate();
    const toggleMyListingMenu = () => setMyListingOpen(!isMyListingOpen); // Toggle function for My Listing

    const menuTextStyles = {
        primaryTypographyProps: {
            style: { color: theme.palette.text.primary },
        },
    };

    // Function to mark a section as complete
    const markAsComplete = (subMenuKey) => {
        setCompletionStatus((prevState) => ({
            ...prevState,
            [subMenuKey]: true,
        }));
    };

    // Filter navigation based on role and authentication
    const filteredNav = nav.filter((route) => {

        if (route.path === "/SiteOnboarding" && role_id === 3) {
            return false;
        }
        if (route.path === "/TrainerOnboarding" && role_id === 2) {
            return false;
        }
        if (isAuthenticated && route.path === "/SiteOnboarding") {
            return false;
        }
        if (isAuthenticated && route.path === "/TrainerOnboarding") {
            return false;
        }

        if (!route.isPrivate && route.isMenu) {
            return true;
        }
        if (isAuthenticated && route.isPrivate && route.isMenu) {
            return true;
        }
        return false;
    });

    const getMenuClass = (screen) => {
        return activeScreen === screen ? 'active-sidemenu-item' : '';
    };

    return (
        <div className="onboard-side-menu">
            <div className="menu-header">
                <div className="logo" style={{ ...spacingStyles.mr20, ...spacingStyles.ml10, ...spacingStyles.mt10 }}
                    onClick={() => {
                        if (isAuthenticated) {
                            navigation(role_id === 2 ? "/GymDash" : role_id === 3 ? "/TrainerDash" : "/");
                        } else {
                            navigation("/Login");
                        }
                    }}>
                    <img
                        className="noLogLogo"
                        src={darkMode ? require("./../assets/logo_light.png") : require("./../assets/logo_dark.png")}
                        alt="Logo"
                    />
                </div>
                <DarkModeSwitch darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
            </div>

            <MenuList className="side-menu-list" style={{ width: '100%' }}>
                {!isAuthenticated && (
                    <>
                        <MenuItem key="register" onClick={() => onMenuClick("Register")}>
                            <ListItemText primary="Register" {...menuTextStyles} />
                        </MenuItem>
                        <MenuItem key="login" onClick={() => onMenuClick("Login")}>
                            <ListItemText primary="Login" {...menuTextStyles} />
                        </MenuItem>
                    </>
                )}
                {isAuthenticated && (
                    <div>
                        <MenuItem
                            key="MyListing"
                            className="mui-menu-item"
                            onClick={toggleMyListingMenu}
                        >
                            <ListItemIcon style={{ minWidth: '40px', color: darkMode ? '#fff' : '#000' }}>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="My Listing" {...menuTextStyles} />
                            <ListItemIcon style={{ minWidth: '40px', marginLeft: 'auto' }}>
                                {isMyListingOpen ? <ExpandMore /> : <ChevronLeftIcon />}
                            </ListItemIcon>
                        </MenuItem>

                        {/* Collapsible Sub-menu for My Listing */}
                        <Collapse in={isMyListingOpen} timeout="auto" unmountOnExit>
                            <OnboardingSideMenu
                                activeScreen={activeScreen}
                                onMenuClick={(subKey) => {
                                    onMenuClick(subKey);
                                    markAsComplete(subKey);  // Mark the subitem as complete
                                }}
                                completionStatus={completionStatus}  // Pass completion status
                            />
                        </Collapse>
                    </div>)}
                {/* Dynamically map nav array for other links */}
                {filteredNav.map((route, index) => {
                    return (
                        <MenuItem
                            key={index}
                            className={`mui-menu-item ${getMenuClass(route.name)}`}
                            onClick={() =>{ 
                                //console.log(route.name); 
                                onMenuClick(route.name);
                            }}
                        >
                            <ListItemIcon style={{ minWidth: '40px', color: darkMode ? '#fff' : '#000' }}>
                                {route.icon ? route.icon : <HomeIcon />}
                            </ListItemIcon>
                            <ListItemText primary={route.name} {...menuTextStyles} />
                        </MenuItem>
                    );
                })}

                {/* Register and Login Links for Guests */}

            </MenuList>
        </div>
    );
};

export default DeskSideMenu;
