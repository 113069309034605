import React from "react";
import StarIcon from "@mui/icons-material/Star";
// import LocationMarker from "../Common/Misc/LocationMarker";
//import BackButton from "../Common/Buttons/BackButton";

import { spacingStyles } from "../../assets/styles/global";
import { colors } from "../../assets/styles/global"; // Import the colors object or define it if not imported already

export default function GymDetailsHeader({ Data, showProfilePic, horizontal = false, mode = "dark",
}) {
  // Define the style for text when mode is "light"
  //console.log("mode:", mode);
  const textStyle = mode === "light" ? { color: colors.black } : {};
  //console.log("textStyle:", textStyle);

  var horBackgroundStyle = {
    backgroundImage: "url(" + Data?.image + ")",
    height: "108px",
    width: "140px",
    // maxHeight: '96px',
    // width: '6rem',
    borderRadius: "0.5em",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  var vertBackgroundStyle = {
    backgroundImage: "url(" + Data?.image + ")",
    height: "40vw",
    maxHeight: "360px",
    width: "100%",
    borderRadius: "0.25em",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  // Generate a random rating between 4.5 and 5.0
  const randomRating = Math.random() * (5.0 - 4.5) + 4.5;

  // Generate a random number of ratings between 7 and 28
  const randomNumRatings = Math.floor(Math.random() * (28 - 7 + 1)) + 7;


  if (horizontal && Data) {
    return (
      <div className="flexRow">
        {/* <BackButton></BackButton> */}
        <div>
          {showProfilePic ? (
            <div className="gym-hori-img" style={horBackgroundStyle}></div>
          ) : null}
        </div>
        <div
          className="flexCol centerView "
          style={{ minWidth: 150, paddingLeft: 5 }}
        >
          <h3
            className="h1"
            style={{ ...spacingStyles.mb0, ...spacingStyles.mt0, ...textStyle.color }} // Apply textStyle here
          >
            <b>{Data?.name}</b>
          </h3>
          <div className="gray" style={textStyle}> {/* Apply textStyle here */}
            {/* <LocationMarker showBorder={false} /> */}
            <span className="h2"> {Data?.location.city}</span>
          </div>

          <div className="flexCol" style={{ ...spacingStyles.mt10 }}>
            {/* <div className="flexRow yellow">
              {[...Array(Data.rating || 0).keys()].map((i) => (
                <div key={i}>
                  <StarIcon />
                </div>
              ))}
            </div>
            <span className="h2 "> {Data?.numRatings || 0} reviews</span> */}
            <div className={`flexRow ${mode === "light" ? 'yellow_dark' : 'yellow'}`} > 
              {[...Array(5).keys()].map((i) => (
                <div key={i}>
                  <StarIcon />
                </div>
              ))}
            </div>
            <span className="h2">{randomNumRatings} reviews</span>

            
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ ...spacingStyles.flexRow, }}>
        {/* <BackButton></BackButton> */}
        <div className="centerView">
          {showProfilePic ? (
            <>
              <div style={vertBackgroundStyle}></div>
            </>
          ) : null}
        </div>
        <div
          style={{
            ...spacingStyles.p10,
            ...spacingStyles.flexRow,
            ...spacingStyles.fullWidth,
            ...spacingStyles.alignCenter,
          }}
        >
          <div className="flexCol centerView">
            <h1
              className="h1 center"
              style={{
                ...spacingStyles.pr10,
                ...textStyle.color, // Apply textStyle here
              }}
            >
              {Data?.name}
            </h1>
            {/* <div className="flexRow yellow">
              {!!Data?.rating &&
                [...Array(Data.rating).keys()].map((i) => (
                  <div key={i}>
                    <StarIcon />
                  </div>
                ))}
            </div>
            <span className="h2 white"> {Data?.numRatings || 0} reviews</span> */}
            <div className="flexRow yellow">
              {[...Array(5).keys()].map((i) => (
                <div key={i}>
                  <StarIcon />
                </div>
              ))}
            </div>
            <span className="h2 white">{randomNumRatings} reviews</span>

            <div
              className="flexRow alignCenter white"
              style={{ ...spacingStyles.pt20, ...textStyle.color }} // Apply textStyle here
            >
              {/* <LocationMarker showBorder={false} /> */}
              <span className="h2 light_gray"> {Data?.location.city}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
