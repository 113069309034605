import React, { useMemo, useState } from "react";
import moment from "moment";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCheck, FaCircle } from "react-icons/fa";
import { MdOutlineQuestionMark } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { useSelector } from "react-redux";

import { colors, globalStyles } from "../../assets/styles/global";
import calendarReschedule from "../../assets/calendar-reschedule.png";
import { cancelBooking } from "../../Services/APIHandlers/ScheduleApi";
import getErrorMessage from "../../Services/Helpers/getErrorMessage";

const ConfirmedCard = ({ item: { bookingData, partnerData } }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuVisible = Boolean(anchorEl);
  const [cancelling, setCancelling] = useState(false);

  const token = useSelector((state) => state.auth.token);

  const time = useMemo(() => {
    let result = "";

    if (bookingData.class_start_time) {
      result += moment(bookingData.class_start_time).format("hh:mma");

      if (bookingData.class_end_time) {
        result += "-" + moment(bookingData.class_end_time).format("hh:mma");
      }
    }

    return result;
  }, [bookingData]);

  const timeRemaining = useMemo(() => {
    const { class_start_time } = bookingData;

    if (!class_start_time) return "";

    const t = moment(class_start_time).diff(moment(), "hours");

    return t > 0 ? t + " Hrs Remaining" : "";
  }, [bookingData]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCancelBooking = async () => {
    try {
      setCancelling(true);

      const { data } = await cancelBooking(token, bookingData.booking_id);
      console.log(">>cancelBooking", data);

      if (data?.error && data?.message) {
        alert(data.message);
      }
    } catch (error) {
      console.log(">>>Error in handleCancelBooking", error);
      alert(getErrorMessage(error));
    } finally {
      setCancelling(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: colors.card_gray.color,
        borderRadius: 10,
        padding: "12px 15px", // Valid CSS shorthand for padding
      }}
    >
      <div
        style={{
          display: "flex", // Use flexbox for layout
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ ...globalStyles.text, fontSize: 15, fontWeight: "600" }}>
          {time}
        </div>

        <div
          style={{
            display: "flex", // Use flexbox for layout
            alignItems: "center",
          }}
        >
          <div
            style={{
              ...globalStyles.text,
              color: colors.light_gray.color,
              marginRight: 6,
              fontSize: 13,
              fontWeight: "500",
            }}
          >
            Not Checked In
          </div>
          <div
            style={{
              backgroundColor: "transparent",
              borderRadius: "50%", // Rounded border
              border: `1px solid ${colors.light_gray.color}`,
              padding: 2,
            }}
          >
            <FaCheck color={colors.white.color} size={10} />
          </div>

          <IconButton onClick={handleMenuOpen}>
            <BsThreeDotsVertical
              color={colors.white.color}
              size={25}
              style={{ marginLeft: 5 }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={menuVisible}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                borderRadius: 8,
                marginTop: 10,
              },
            }}
          >
            <MenuItem onClick={handleMenuClose}>
              <FaCircle
                size={18}
                color={colors.green.color}
                style={{ marginRight: 10 }}
              />
              Complete
            </MenuItem>
            <MenuItem
              onClick={handleCancelBooking}
              disabled={cancelling}
              style={{ opacity: cancelling ? 0.3 : 1 }}
            >
              <IoIosCloseCircleOutline
                size={18}
                color={colors.red.color}
                style={{ marginRight: 10 }}
              />
              Cancel
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <img
                src={calendarReschedule}
                style={{ width: 18, height: 18, marginRight: 10 }}
                alt="Calendar Reschedule"
              />
              Reschedule
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <MdOutlineQuestionMark
                size={18}
                color={colors.dark_gray.color}
                style={{ marginRight: 10 }}
              />
              No Show
            </MenuItem>
          </Menu>
        </div>
      </div>

      <div style={{ ...globalStyles.h3, marginTop: 10 }}>
        {partnerData.name}
      </div>

      <div
        style={{
          display: "flex", // Use flexbox for layout
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <div style={{ marginTop: 15 }}>
          <img
            src={partnerData.image}
            style={{ width: 45, height: 45, borderRadius: "50%" }}
            alt="User"
          />
          <div
            style={{ ...globalStyles.text, fontWeight: "500", marginTop: 6 }}
          >
            {bookingData.pname}
          </div>
        </div>

        <div disabled>
          <div style={globalStyles.gradientButton}>
            <div style={globalStyles.gradientButtonText}>Check-in</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmedCard;
