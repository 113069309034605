import axios from "axios";
import config from '../../config';

// Helper function to handle API calls and errors //added
const apiCall = async (url, token) => {
  try {
    const response = await axios.get(url + token);
    return { success: true, data: response.data };
  } catch (error) {
    console.error("API Call Error:", error);
    return { success: false, message: error.response?.data?.message || error.message };
  }
};

export const getWalletData = async (token) => {
  return await apiCall(`${config.apiUrl}Wallet/getData/`, token);
};

export const getCurrentData = async (token) => {
  return await apiCall(`${config.apiUrl}Wallet/getCurrentData/`, token);
};

export const setupTransfer = async (token) => {
  return await apiCall(`${config.apiUrl}Stripe/getConnectURl/`, token);
};

export const cashOut = async (token) => {
  return await apiCall(`${config.apiUrl}Wallet/cashOut/`, token);
};

